<script setup>
import { useRoute } from 'vue-router';
import { ref, watch } from 'vue';

const route = useRoute();
const home = { icon: 'pi pi-home', to: '/' };
const breadcrumbRoutes = ref([]);
const breadcrumbRouteName = ref([]);

const setBreadcrumbRoutes = () => {
    if (route.meta.breadcrumb) {
        breadcrumbRoutes.value = route.meta.breadcrumb;
        return;
    }

    breadcrumbRoutes.value = route.fullPath
        .split('/')
        .filter((item) => item !== '')
        .filter((item) => isNaN(Number(item)))
        .map((item) => item.charAt(0).toUpperCase() + item.slice(1));
};

watch(
    route,
    () => {
        setBreadcrumbRoutes();
    },
    { immediate: true }
);
</script>

<template>
    <nav class="layout-breadcrumb">
        <ol>
            <li>
                <router-link :to="home.to" style="color: inherit">
                    <i :class="home.icon"></i>
                </router-link>
            </li>
            <li class="layout-breadcrumb-chevron">/</li>
            <template v-for="(breadcrumbRoute, i) in breadcrumbRoutes" :key="breadcrumbRoute">

                <li v-if="breadcrumbRoutes.length - 1 != i">
                    <router-link :to="{name: breadcrumbRoute.name}">
                        {{ breadcrumbRoute.title }}
                    </router-link>
                </li>
                <li v-else>{{ breadcrumbRoute.title }}</li>
                <li v-if="i !== breadcrumbRoutes.length - 1" class="layout-breadcrumb-chevron">/</li>
            </template>
        </ol>
    </nav>
</template>
