import { createRouter, createWebHistory } from 'vue-router';
import AppLayout from '@/layout/AppLayout.vue';

const routes = [
    {
        path: '/',
        component: AppLayout,
        children: [
            {
                path: '/ps-customer-server/list',
                name: 'ps-customer-server.list',
                meta: {
                    breadcrumb: [{
                        title: 'PS Customer Server',
                        name: 'ps-customer-server.list'
                    }]
                },
                component: () => import('@/views/ps-customer-server/List.vue')
            },
            {
                path: '/ps-customer-server/add',
                name: 'ps-customer-server-add',
                meta: {
                    breadcrumb: [{
                        title: 'PS Customer Server',
                        name: 'ps-customer-server.list'
                    },
                    {
                        title: 'Add',
                        name: 'ps-customer-server-add'
                    }]
                },
                component: () => import('@/views/ps-customer-server/Add.vue')
            },
        ]
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/pages/auth/Login.vue')
    },
    {
        path: '/404',
        name: '404',
        component: () => import('@/views/pages/404.vue'),
    },
    {
        path: '/:pathMatch(.*)*',
        redirect: '/404'
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior() {
        return { left: 0, top: 0 };
    }
});

export default router;
