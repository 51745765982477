import axios, { type AxiosInstance, type AxiosRequestConfig } from "axios";
import { inject, type InjectionKey, type Plugin } from "vue";
import { useUserSession } from "@/stores/userSession";
import type { ToastServiceMethods } from "primevue/toastservice";
import { type Router } from "vue-router";

type axiosParam = {
  toast: ToastServiceMethods;
  router: Router;
};

interface AxiosCustomInstance {
  httpClient: AxiosInstance;
  $request: (config: AxiosRequestConfig) => Promise<any>;
  $get: (url: string, config?: AxiosRequestConfig) => Promise<any>;
  $delete: (url: string, config?: AxiosRequestConfig) => Promise<any>;
  $post: (url: string, data?: any, config?: AxiosRequestConfig) => Promise<any>;
  $put: (url: string, data?: any, config?: AxiosRequestConfig) => Promise<any>;
  $patch: (
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ) => Promise<any>;
}
const symbol = Symbol("useAxios") as InjectionKey<AxiosCustomInstance>;

function Axios({ toast, router }: axiosParam): AxiosCustomInstance {
  const httpClient = axios.create({
    baseURL: import.meta.env.VITE_API_BASE_URL,
    responseType: "json",
  });

  httpClient.interceptors.request.use((req: any) => {
    const userSession = useUserSession();
    if (
      userSession.token != "" ||
      (userSession.token === "" && req.url?.split("/").includes("login"))
    ) {
      req.headers = {
        ...req.headers,
        Authorization: `${userSession.token}`,
      };
      return req;
    } else {
      return {
        ...req,
        signal: AbortSignal.abort(),
      };
    }
  });

  httpClient.interceptors.response.use(
    (response) => {
      if (response.data.status === "failed") {
        toast.remove({ group: "message" });
        toast.add({
          group: "message",
          detail: response.data.message,
          severity: "error",
          life: 5000,
          closable: true,
        });
      }
      return response;
    },
    (error) => {
      const userSession = useUserSession();
      if (error?.response?.data?.message === "INVALID_SESSION_ID") {
        userSession.setPsCustomerAuthDetail({
          access_token: "",
          active: false,
          exp: 0,
          iat: 0,
        });
        router.push({ name: "ps-customer-server.list" });
      }
      if (error?.response?.status === 401) {
        userSession.logoutUser();
        window.location.reload();
      }
      return Promise.reject(error);
    }
  );

  return {
    httpClient,
    $request: async (config: AxiosRequestConfig) =>
      (await httpClient.request(config)).data,
    $get: async (url: string, config?: AxiosRequestConfig) =>
      (await httpClient.get(url, config)).data,
    $delete: async (url: string, config?: AxiosRequestConfig) =>
      (await httpClient.delete(url, config)).data,
    $post: async (url: string, data?: any, config?: AxiosRequestConfig) =>
      (await httpClient.post(url, data, config)).data,
    $put: async (url: string, data?: any, config?: AxiosRequestConfig) =>
      (await httpClient.put(url, data, config)).data,
    $patch: async (url: string, data?: any, config?: AxiosRequestConfig) =>
      (await httpClient.patch(url, data, config)).data,
  };
}

export const axiosPlugin: Plugin = {
  install(app, options) {
    app.provide(
      symbol,
      Axios({
        toast: app.config.globalProperties.$toast,
        router: app.config.globalProperties.$router,
      })
    );
  },
};

export function useAxios(): AxiosCustomInstance {
  return inject(symbol) as AxiosCustomInstance;
}
