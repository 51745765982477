import { useUserSession } from "@/stores/userSession";
import { type App, type Plugin } from "vue";

export const routerGuardPlugin: Plugin = {
  install: (app: App) => {
    const userSession = useUserSession();

    app.config.globalProperties.$router.beforeEach((to, from, next) => {
      if (to.path !== "/login" && !userSession.token) {
        next("/login");
      } else if (to.path === "/login" && userSession.token) {
        next("/ps-customer-server");
      } else if (
        to.path === "/admin" &&
        userSession.token &&
        !userSession.isSalesForceLoggedIn
      ) {
        next("/ps-customer-server/list");
      } else {
        next();
      }
    });
  },
};
